.checkbox {
  @apply appearance-none h-5 w-5 relative
    rounded-sm checked:bg-primary-variant border-2
    border-secondary checked:border-primary-variant
    transition-colors duration-150 cursor-pointer;
}

.checkbox::after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("/assets/images/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: scale(0);
  transition: transform 0.2s;
}

.checkbox:checked::after {
  transform: scale(1);
}

.checkbox:disabled {
  @apply opacity-50 cursor-not-allowed;
}
