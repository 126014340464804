@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/Rubik-VariableFont.ttf");
  font-display: swap;
  font-weight: 400 700;
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/Rubik-Italic-VariableFont.ttf");
  font-style: italic;
  font-display: swap;
  font-weight: 400 700;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-VariableFont.ttf");
  font-display: swap;
  font-weight: 400 700;
}

@layer base {
  html {
    font-family: "Inter", system-ui, sans-serif;
  }
}

html,
body {
  height: 100%;
  display: block;
}
