.outline-button,
.filled-button {
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 0;
  font-size: theme("fontSize.sm");
  line-height: 1;
  font-weight: 600;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  color: theme("colors.secondary");
}

.outline-button:disabled,
.filled-button:disabled {
  cursor: not-allowed;
}

.outline-button {
  border-width: theme("borderWidth.DEFAULT");
  border-color: theme("colors.primary");
  background-color: transparent;
  border-style: solid;
}

.outline-button:disabled {
  border-color: theme("colors.gray.300");
  color: theme("colors.gray.400");
}

.filled-button {
  background-color: theme("colors.primary");
}

.filled-button:disabled {
  background-color: theme("colors.gray.300");
  color: theme("colors.gray.400");
}
