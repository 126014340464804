.input {
  appearance: none;
  height: theme("height.14");
  padding: 0 theme("padding.4");
  font-size: 1rem;
  line-height: 1;
  border-radius: theme("borderRadius.md");
  border-width: 1px;
  border-style: solid;
  border-color: theme("colors.stroke");
  box-sizing: border-box;
}

.input:disabled {
  background-color: theme("colors.disabled");
  color: theme("colors.stroke");
}

.input.ng-invalid.ng-touched {
  border-color: theme("colors.error");
}

select.input {
  text-overflow: ellipsis;
  padding-right: theme("padding.8");
  background-position: right calc(theme("padding.4") * -1) center;
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-origin: content-box;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: %230f111e'></polygon></svg>");
}
